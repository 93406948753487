// extracted by mini-css-extract-plugin
export var blueText = "people-module--blueText--444e9";
export var companyTitle = "people-module--companyTitle--8b52e";
export var container = "people-module--container--7eec2";
export var employeeName = "people-module--employeeName--71ee3";
export var employeeTags = "people-module--employeeTags--18dbd";
export var image = "people-module--image--a4a5c";
export var imageContainer = "people-module--imageContainer--f4edb";
export var linkedinURL = "people-module--linkedinURL--cdbd0";
export var more = "people-module--more--7ae09";
export var moreContent = "people-module--moreContent--c9fac";
export var textContainer = "people-module--textContainer--f6f51";
export var title = "people-module--title--89e08";
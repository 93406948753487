import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import '../styles/fonts.css'
// import { currentLang } from '../helpers/i18n'

import Layout from '../components/partials/layout'
import Header from '../components/home/header'
import Text from '../components/home/text'
import Skeeled from '../components/skeeled'
import People from '../components/home/people'

class RootIndex extends React.Component {
  state = { frp: [] }

  componentDidMount() {
    const people = get(this, 'props.data.allContentfulPerson.nodes')
    // Shuffle array
    const shuffled = people.sort(() => 0.5 - Math.random())
    // Get sub-array of first n elements after shuffled
    this.setState({ frp: shuffled.slice(0, 4) })
  }

  render() {
    const cookies = get(this, 'props.data.contentfulCookies')
    const menuItems = get(this, 'props.data.allContentfulMenuItem.nodes')
    const headerVideoUrl = get(this, 'props.data.video.file.url')
    const fallbackMain = get(this, 'props.data.fallback_main')
    const workAt = get(this, 'props.data.workAt')
    const why = get(this, 'props.data.why')

    const urlColleagues = menuItems.find((m) => m.code === 'colleagues').url

    return (
      <Layout
        location={this.props.location}
        menuItems={menuItems}
        cookies={cookies}
      >
        <Header videoUrl={headerVideoUrl} gatsbyImage={fallbackMain} />
        <Text text={workAt} />
        <Skeeled />
        <Text text={why} />
        <People peeps={this.state.frp} url={urlColleagues} />
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery($locale: String!) {
    contentfulCookies {
      linkName
      linkUrl
      title
      content
    }
    allContentfulMenuItem(sort: { fields: [order] }) {
      nodes {
        node_locale
        code
        name
        url
      }
    }
    video: contentfulAsset(title: { eq: "video ayv" }) {
      file {
        url
      }
    }
    fallback_main: contentfulAsset(title: { eq: "fallback_main" }) {
      gatsbyImageData(
        layout: FULL_WIDTH
        placeholder: BLURRED
        width: 6789
        height: 4531
      )
    }
    workAt: contentfulTextComponent(
      code: { eq: "work-at" }
      node_locale: { eq: $locale }
    ) {
      code
      blueText
      blackText
      content {
        raw
      }
    }
    why: contentfulTextComponent(
      code: { eq: "why" }
      node_locale: { eq: $locale }
    ) {
      code
      blueText
      blackText
      content {
        raw
      }
    }
    allContentfulPerson(
      filter: { companies: { in: [$locale] }, node_locale: { eq: $locale } }
    ) {
      nodes {
        name
        title
        shortBio {
          shortBio
        }
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 240
            height: 240
          )
        }
        tags
        linkedInUrl
      }
    }
  }
`

import React from 'react'

import * as styles from '../styles/modules/skeeled.module.css'

const Skeeled = () => {
  /* skeeled plugin */
  /* ALD LU: https://app.skeeled.com/board/61d854bd48fc665c45d5c71e */
  /* ALD BE: https://app.skeeled.com/board/61d85525bcde44bf8b4975b7 */
  /* w/spontaneous link */

  let urlString =
    'https://app.skeeled.com/board/61d854bd48fc665c45d5c71e?language=fr'
  const boardUrl = new URL(urlString)

  if (typeof window !== 'undefined') {
    window.addEventListener(
      'message',
      (event) => {
        if (event.origin === boardUrl.origin) {
          const data = JSON.parse(event.data)
          if (!data.windowSize) return // Not size information
          const windowSize = data.windowSize

          let sw = document.getElementById('skeeledWrapper')
          sw.style.height = windowSize.height + 'px'
          console.log(windowSize.height)
        }
      },
      false
    )
  }

  return (
    <div id="skeeledWrapper" className={styles.skeeledWrapper}>
      <iframe
        src={urlString}
        width={'100%'}
        height={'100%'}
        title="skeeled jobs plugin ALD BE"
      ></iframe>
    </div>
  )
}

export default Skeeled

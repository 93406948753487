import React from 'react'

import * as styles from '../../styles/modules/home/people.module.css'
import { T } from '../../helpers/i18n'
import { GatsbyImage } from 'gatsby-plugin-image'

const People = ({ peeps, url }) => {
  let [activePerson, setActivePerson] = React.useState(null)
  const onClick = (person, e) => {
    if (person === activePerson) {
      setActivePerson(null)
    } else {
      if (e) e.target.scrollIntoView({ behavior: 'smooth' })
      setActivePerson(person)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <div className={styles.title}>
          <span className={styles.blueText}>{T('COLLEAGUES_BLUE')}</span>
          <span className="text-primary">{T('COLLEAGUES_BLACK')}</span>
        </div>
        <div className="flex flex-wrap justify-around my-6">
          {peeps.map((person) => (
            <button
              className="m-3"
              onClick={(e) => onClick(person, e)}
              onKeyPress={() => onClick(person)}
              key={person.name}
            >
              <div className={styles.imageContainer}>
                <GatsbyImage
                  className={styles.image}
                  alt={person.name}
                  image={person.image.gatsbyImageData}
                />
                <div
                  className={styles.more}
                  style={activePerson === person ? { display: 'flex' } : {}}
                >
                  <span
                    style={activePerson === person ? { display: 'none' } : {}}
                  >
                    {T('COLLEAGUES_MORE') + ' >'}
                  </span>
                </div>
              </div>
              <div
                id="fill"
                className={styles.moreContent}
                style={activePerson === person ? { display: 'block' } : {}}
              >
                <div className={styles.employeeName}>{person.name}</div>
                <div className={styles.companyTitle}>{person.title}</div>
                <div
                  style={{ display: person.tags ? 'block' : 'none' }}
                  className={styles.employeeTags}
                >
                  {person.tags && person.tags.join(', ')}
                </div>
                <div
                  style={{ display: person.linkedInUrl ? 'block' : 'none' }}
                  className={styles.linkedinURL}
                  onMouseEnter={(e) => {
                    e.currentTarget.querySelector(
                      'img:first-child'
                    ).style.display = 'none'
                    e.currentTarget.querySelector(
                      'img:last-child'
                    ).style.display = 'block'
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.querySelector(
                      'img:first-child'
                    ).style.display = 'block'
                    e.currentTarget.querySelector(
                      'img:last-child'
                    ).style.display = 'none'
                  }}
                >
                  <a href={person.linkedInUrl} target="_blank" rel="noreferrer">
                    <img
                      src="/images/li_logo_2.png"
                      alt="LinkedIn Logo"
                      width={32}
                      height={32}
                    />
                    <img
                      src="/images/li_logo.png"
                      alt="LinkedIn Logo"
                      width={32}
                      height={32}
                      style={{ display: 'none' }}
                    />
                  </a>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: person.shortBio.shortBio.replace(/\n/g, '<br />'),
                  }}
                ></div>
              </div>
            </button>
          ))}
        </div>

        {url && (
          <div className="text-right my-4">
            <a className="text-primary font-bold" href={url}>
              {T('COLLEAGUES_ALL')} &gt;
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default People
